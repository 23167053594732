'use client';

import { SearchIcon } from '@heroicons/react/outline';
import { MapIcon } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslations } from 'next-intl';

import AppFooter from '@/components/atoms/AppFooter';
import AppHero from '@/components/atoms/AppHero';
import AppHeroNew from '@/components/atoms/AppHeroNew';
import AppSection from '@/components/atoms/AppSection';
import InterrailCard from '@/components/atoms/InterrailCard';
import AppHeader from '@/components/organisms/AppHeader';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Meteors } from '@/components/ui/meteors';
import PartnersBanner from 'app/[locale]/lobby/PartnersBanner';
import { useRedirectUrl } from 'context/redirectUrlProvider';
import { DestinationPage } from 'page/types';
import { IExploreNearby, ILiveAnywhere } from 'typings';

import TheyTrustUs from '../../components/atoms/TheyTrustUs';

import DestinationsSection from './DestinationsSection';
import InterrailCarousel from './interrail-pass/InterrailCarousel';
import CarbonComparative from './lobby/CarbonComparative';
import Founders from './lobby/Founders';
import { useOriginName } from 'hooks/useQuerySearch';
import { useState } from 'react';
import { LoginModal } from '@/components/account/LoginModal';
import { NightTrainMapCard } from './NightTrainMapCard';
import TestimonialSection from './TestimonialSection';
interface IHomeDataProps {
  exploreNearby: IExploreNearby[];
  liveAnywhere: ILiveAnywhere[];
  destinations: DestinationPage[];
}

const FEATURE_FLAG_PREORDER = false;
const FEATURE_FLAG_ITALY_HISTORICAL = false;
const FEATURE_FLAG_INTERRAIL = false;

const jsonLdWebsite = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  url: 'https://www.railee.com/',
  potentialAction: {
    '@type': 'SearchAction',
    target: 'https://www.railee.com/search?destination={destination}',
    'query-input': 'required name=destination',
  },
  name: 'Railee',
  alternateName: 'Railee Train Tickets',
  description:
    'Railee is a train ticket search engine that helps you find the best train tickets for your journey.',
  sameAs: [
    'https://www.linkedin.com/company/railee/',
    'https://www.instagram.com/railee.eu/',
  ],
};

const jsonLdOrganisation = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  name: 'Railee',
  description:
    'Railee is a train ticket search engine that helps you find the best train tickets for your journey.',
  image: 'https://www.railee.com/assets/favicons/logo_railee.svg',
  url: 'https://www.railee.com/',
  priceRange: '€',
  openingHours: 'Mo-Su 00:00-24:00',
  address: {
    '@type': 'PostalAddress',
    addressLocality: 'Paris',
    addressCountry: 'FR',
    postalCode: '75012',
    streetAddress: '8b rue Abel',
  },
};

const ClientPage = ({ exploreNearby, destinations }: IHomeDataProps) => {
  const t = useTranslations('lobby');
  const [, , setRedirectUrlToCurrentPage] = useRedirectUrl();
  const [originName] = useOriginName();
  const [showLogin, setShowLogin] = useState<boolean>(false);

  const italianDestinationKeywords = [
    'Rome',
    'Venezia',
    'Bari',
    'cinque terre',
    'Dolomites',
  ];

  // TODO: move this to Sanity or make explicit why we are hardcoding this

  const hardcodedScotlandDestinations = [
    {
      title: 'Edinburgh',
      slug: { current: `/search?origin=${originName}&destination=Edinburgh+Waverley` },
      price: 119,
      coverImage: '/assets/live-anywhere/Edinburgh%20Waverley.jpg',
    },
    {
      title: 'Glasgow',
      slug: { current: `/search?origin=${originName}&destination=Glasgow` },
      price: 119,
      coverImage: '/assets/live-anywhere/Glasgow.jpg',
    },
    {
      title: 'Inverness',
      slug: { current: `/search?origin=${originName}&destination=Inverness` },
      price: 178,
      coverImage: '/assets/interrail/Loch-Ness.jpg',
    },
    {
      title: 'Aberdeen',
      slug: { current: `/search?origin=${originName}&destination=Aberdeen` },
      price: 168,
      coverImage: '/assets/live-anywhere/Aberdeen.jpg',
    },
    {
      title: 'Fort William',
      slug: { current: `/search?origin=${originName}&destination=Fort+William` },
      price: 399,
      coverImage: '/assets/interrail/Inverness-cliff.jpg',
    },
  ];

  const hardcodedItalianDestinations = [
    {
      title: 'Venezia',
      slug: { current: `/search?origin=${originName}&destination=Venezia` },
      price: 165,
      coverImage: '/assets/interrail/vienna.jpg',
    },
    {
      title: 'Bari',
      slug: { current: `/search?origin=${originName}&destination=Bari` },
      price: 150,
      coverImage: '/assets/live-anywhere/Bari.jpg',
    },
    {
      title: 'Cinque Terre',
      slug: { current: `/search?origin=${originName}&destination=Monterosso` },
      price: 159,
      coverImage: '/assets/interrail/italy.jpg',
    },
    {
      title: 'Lago Como',
      slug: { current: `/search?origin=${originName}&destination=Como` },
      price: 90,
      coverImage: '/assets/interrail/como.jpg',
    },
    {
      title: 'Rome',
      slug: { current: `/search?origin=${originName}&destination=Roma` },
      price: 120,
      coverImage: '/assets/live-anywhere/Rome.jpg',
    },
  ];

  const hardcodedHistoricalCities = [
    {
      title: 'Berlin',
      slug: { current: `/search?origin=${originName}&destination=Berlin` },
      price: 84,
      coverImage: '/assets/live-anywhere/Berlin.jpg',
    },
    {
      title: 'Praha',
      slug: { current: `/search?origin=${originName}&destination=Praha` },
      price: 159,
      coverImage: '/assets/live-anywhere/Praha.jpg',
    },
    {
      title: 'Zagreb',
      slug: { current: `/search?origin=${originName}&destination=Zagreb` },
      price: 89,
      coverImage: '/assets/live-anywhere/Zagreb.jpg',
    },
    {
      title: 'Budapest',
      slug: { current: `/search?origin=${originName}&destination=Budapest` },
      price: 124,
      coverImage: '/assets/live-anywhere/Budapest.jpg',
    },
    {
      title: 'Vienna',
      slug: { current: `/search?origin=${originName}&destination=Wien` },
      price: 155,
      coverImage: '/assets/live-anywhere/Wien.jpg',
    },
  ];
  const italianDestinations = destinations.filter((destination) =>
    italianDestinationKeywords.some((keyword) => destination.title.includes(keyword))
  );

  const historicalDestinationKeywords = [
    'Berlin',
    'Krakow',
    'Zagreb',
    'Budapest',
    'Historical Vienna',
  ];

  const historicalCities = destinations.filter((destination) =>
    historicalDestinationKeywords.some((keyword) => destination.title.includes(keyword))
  );

  return (
    <>
      <AppHeader
        exploreNearby={exploreNearby}
        largeSearchBarByDefault={false}
        landingPage={true}
      />
      <main>
        <div className="hidden md:block">
          <AppHeroNew
            cover="/assets/heros/cinque-terre-train.webp"
            exploreNearby={exploreNearby}
          />
        </div>
        <div className="md:hidden">
          <AppHero
            cover="/assets/heros/cinque-terre-train.webp"
            exploreNearby={exploreNearby}
          />
        </div>

        <AppSection title="">
          <PartnersBanner />
        </AppSection>
        {FEATURE_FLAG_INTERRAIL && (
          <>
            <AppSection title="">
              <Link href="/interrail-trip-estimation">
                <div className="relative w-full h-[300px] md:h-[400px] overflow-hidden rounded-3xl group">
                  <Image
                    alt="interrail-passes"
                    src="/assets/heros/heroInterrail.jpg"
                    width={1088}
                    height={400}
                    className="group-hover:scale-105 transition-transform group-hover:cursor-pointer w-full h-full object-cover"
                  />
                  <div className="absolute max-sm:top-6 md:bottom-7 left-6">
                    <h2 className="text-3xl md:text-4xl font-semibold text-white pr-3">
                      {t('interrailBanner.title')}
                    </h2>
                  </div>
                  <Button
                    className="absolute bottom-4 right-4 md:hidden"
                    id="button-interrail-banner"
                    variant="secondary"
                  >
                    {t('interrailBanner.subCta')}
                  </Button>
                  <div className="md:block absolute hidden -right-8 bottom-1/2 hover:-translate-x-2 transition-transform translate-y-1/2">
                    <div className="bg-secondary opacity-85 md:opacity-100 group-hover:bg-white flex rounded-l-full">
                      <span className="text-primary font-semibold text-3xl p-8 md:p-12">
                        {t('interrailBanner.cta')}
                        <p className="text-slate-500 text-lg font-normal">
                          {t('interrailBanner.subCta')}
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </AppSection>
            <AppSection title={t('interrailCarousel.title')}>
              <InterrailCarousel />
            </AppSection>
          </>
        )}

        <DestinationsSection
          destinations={hardcodedScotlandDestinations}
          title={t('Scotland.title')}
          subtitle={t('Scotland.subtitle')}
          backgroundImage={'/assets/heros/Highland-Cow.jpg'}
          button={t('Scotland.button')}
        />

        <AppSection title="">
          <TheyTrustUs />
        </AppSection>

        <AppSection title="">
          <Card className="w-full flex flex-col md:flex-row md:h-100 items-center text-center relative rounded-3xl overflow-hidden bg-orange/10">
            <CardHeader className="justify-center basis-1/2 w-full p-8">
              <CardTitle className="text-4xl font-semibold text-left sm:text-center">
                {t.rich('features.feature2', {
                  color: (chunks) => (
                    <p className="text-orange inline-block items-center">{chunks}</p>
                  ),
                  br: () => <br />,
                })}
              </CardTitle>
            </CardHeader>
            <CardContent className="flex items-center relative w-full h-full justify-center bg-orange gap-x-2 gap-y-2 basis-1/2 p-7">
              <Image
                alt="optimized-journeys"
                src="/assets/screenshots/paris_venise.gif"
                width={896}
                height={700}
                style={{ width: 'auto', height: '70%' }}
              />
              <Link
                title="Search-Paris-to-Venezia"
                href="/search?origin=Paris&destination=Venezia"
              >
                <Button
                  id="button-search-paris-venezia"
                  variant="secondary"
                  className="hidden md:block absolute bottom-4 left-1/2 transform -translate-x-1/2"
                >
                  <SearchIcon className="h-4 text-primary inline-block mr-1.5" />
                  <span>{t('features.feature2Btn')}</span>
                </Button>
              </Link>
            </CardContent>
          </Card>
        </AppSection>
        <AppSection title="">
          <Card className="w-full flex flex-col md:flex-row md:h-100 items-center text-center relative rounded-3xl overflow-hidden bg-blue-500/10">
            <CardHeader className="justify-center basis-1/2 w-full p-8">
              <CardTitle className="text-4xl font-semibold text-left sm:text-center max-w-72 self-start sm:self-center">
                {t.rich('features.feature1', {
                  color: (chunks) => (
                    <p className="text-blue-500 inline-block items-center">{chunks}</p>
                  ),
                  br: () => <br />,
                })}
              </CardTitle>
            </CardHeader>
            <CardContent className="flex w-full h-full justify-center items-end bg-blue-500 gap-x-2 gap-y-2 basis-1/2 p-7 pb-0">
              <Image
                alt="Railee application train ticket search page"
                src="/assets/screenshots/phone_search.png"
                width={620}
                height={1020}
                quality={100}
                style={{ width: 'auto', height: '98%' }}
              />
            </CardContent>
          </Card>
        </AppSection>
        <AppSection title="">
          <Card className="w-full flex flex-col md:flex-row md:h-100 items-center text-center relative rounded-3xl overflow-hidden bg-purpple/10">
            <CardHeader className="justify-center basis-1/2 w-full p-8">
              <CardTitle className="text-4xl font-semibold text-left sm:text-center">
                {t.rich('features.feature3', {
                  color: (chunks) => (
                    <p className="text-purpple inline-block items-center">{chunks}</p>
                  ),
                  br: () => <br />,
                })}
              </CardTitle>
            </CardHeader>
            <CardContent className="flex items-end relative w-full h-full justify-center bg-primary gap-x-2 gap-y-2 basis-1/2 p-7 pb-0">
              <Image
                alt="night-trains-booking"
                src="/assets/screenshots/phone_nighttrain.png"
                width={573}
                height={1100}
                style={{ width: 'auto', height: '97%' }}
              />
              <Link href="/search?nightTrain=true">
                <Button
                  id="search-night-train"
                  variant="secondary"
                  className="absolute bottom-4 left-0 transform -translate-x-1/2 hidden md:block"
                >
                  <MapIcon className="h-5 text-primary inline-block mr-1.5" />
                  <span>{t('features.feature3Btn')}</span>
                </Button>
              </Link>
            </CardContent>
          </Card>
        </AppSection>
        {FEATURE_FLAG_INTERRAIL && (
          <AppSection title="">
            <Card className="w-full flex flex-col md:flex-row md:h-100 items-center text-center relative rounded-3xl overflow-hidden bg-orange/10">
              <CardHeader className="justify-center basis-1/2 w-full p-8">
                <CardTitle className="text-4xl font-semibold text-left sm:text-center">
                  {t.rich('features.feature4', {
                    color: (chunks) => (
                      <p className="text-orange inline-block items-center">{chunks}</p>
                    ),
                    br: () => <br />,
                  })}
                </CardTitle>
              </CardHeader>
              <CardContent className="flex w-full h-full items-center justify-center bg-orange gap-x-2 gap-y-2 basis-1/2 p-2">
                <div className="scale-[65%] md:scale-75 rotate-1">
                  <InterrailCard
                    transportMode="train"
                    passNeededDays={4}
                    defaultUnfolded
                  />
                </div>
              </CardContent>
            </Card>
          </AppSection>
        )}
        <AppSection title="">
          <Card className="w-full flex flex-col md:flex-row md:h-100 items-center text-center relative rounded-3xl overflow-hidden bg-blue-500/10">
            <CardHeader className="justify-center basis-1/2 w-full p-8">
              <CardTitle className="text-4xl font-semibold text-left sm:text-center max-w-72 self-start sm:self-center">
                {t.rich('features.feature0', {
                  color: (chunks) => (
                    <p className="text-blue-500 inline-block items-center">{chunks}</p>
                  ),
                  br: () => <br />,
                })}
              </CardTitle>
            </CardHeader>
            <CardContent className="flex w-full h-full justify-center items-end bg-blue-500 gap-x-2 gap-y-2 basis-1/2 p-7 pb-0">
              <Image
                alt="Comparison in Railee app of carbon emissions for plane and train journeys"
                src="/assets/screenshots/footprint.gif"
                width={712}
                height={1037}
                quality={100}
                style={{
                  width: 'auto',
                  height: '97%',
                }}
              />
            </CardContent>
          </Card>
        </AppSection>
        <AppSection title="">
          <Card className="w-full flex flex-col md:flex-row md:h-100 items-center text-center relative rounded-3xl overflow-hidden bg-purpple/10">
            <CardHeader className="justify-center basis-1/2 w-full p-8">
              <CardTitle className="text-4xl font-semibold text-left sm:text-center">
                {t.rich('features.feature5', {
                  color: (chunks) => (
                    <p className="text-purpple inline-block items-center">{chunks}</p>
                  ),
                  br: () => <br />,
                })}
              </CardTitle>
            </CardHeader>
            <CardContent className="flex items-end relative w-full h-full justify-center bg-primary gap-x-2 gap-y-2 basis-1/2 p-7 pb-0">
              <Image
                alt="train-price-map"
                src="/assets/screenshots/phone_pricemap.png"
                width={735}
                height={1088}
                style={{ width: 'auto', height: '97%' }}
              />
              <Link href="/search">
                <Button
                  id="search-price-map"
                  className="absolute bottom-4 left-1/2 transform -translate-x-1/2"
                >
                  {t('features.feature5Btn')}
                </Button>
              </Link>
            </CardContent>
          </Card>
        </AppSection>

        {FEATURE_FLAG_PREORDER && (
          <AppSection title="">
            <Card className="w-full flex flex-col md:flex-row md:h-100 items-center text-center relative rounded-3xl overflow-hidden bg-blue-500/10">
              <CardHeader className="justify-center basis-1/2 w-full p-8">
                <CardTitle className="text-4xl font-semibold text-left md:text-center">
                  {t.rich('features.feature6', {
                    color: (chunks) => <p className="text-blue-500">{chunks}</p>,
                    br: () => <br />,
                  })}
                </CardTitle>
              </CardHeader>
              <CardContent className="flex items-center relative w-full h-full justify-center bg-blue-500 gap-x-2 gap-y-2 basis-1/2 p-7 pb-5">
                <Card className="rounded-2xl rotate-3 max-w-80">
                  <CardHeader className="justify-center basis-1/2 w-full p-4">
                    <div className="flex flex-row gap-x-2 items-center">
                      <div className="h-5 w-5 bg-gray-200 rounded-full" />
                      <div className="h-2 w-8 bg-gray-200 rounded-full" />
                    </div>
                    <CardTitle className="text-xl font-semibold text-left">
                      {t('features.feature6Title')}
                    </CardTitle>
                  </CardHeader>
                  <CardContent className="px-4">
                    <Image
                      alt="trip-image"
                      src="/assets/live-anywhere/Gornergrat.jpg"
                      width={400}
                      height={160}
                      fill
                      className="rounded-xl h-32 object-cover"
                    />
                    <p className="text-center text-orange text-sm mt-2">
                      Paris - Lausanne - Zurich
                    </p>
                  </CardContent>
                  <CardFooter className="flex flex-col gap-3 pb-3">
                    <Label>
                      4 couchettes{' '}
                      <p className="font-semibold text-lg inline-block">379€</p>
                    </Label>
                    <Link href="/" className="w-full">
                      <Button id="button-preorder" className="w-full">
                        {t('features.feature6Btn')}
                      </Button>
                    </Link>
                  </CardFooter>
                </Card>
              </CardContent>
            </Card>
          </AppSection>
        )}

        <DestinationsSection
          destinations={hardcodedHistoricalCities}
          title={t('historicalEurope.title')}
          subtitle={t('historicalEurope.subtitle')}
          backgroundImage={'/assets/heros/hero8.webp'}
          button={t('historicalEurope.button')}
        />

        <AppSection title="">
          <CarbonComparative />
        </AppSection>

        <AppSection title="">
          <NightTrainMapCard />
        </AppSection>

        <DestinationsSection
          destinations={hardcodedItalianDestinations}
          title={t('Italy.title')}
          subtitle={t('Italy.subtitle')}
          backgroundImage={'/assets/heros/manarola.jpg'}
          button={t('Italy.button')}
        />

        <AppSection title={t('about.title')}>
          <Founders />
        </AppSection>

        {FEATURE_FLAG_ITALY_HISTORICAL && (
          <div className="rounded-t-[60px] relative overflow-hidden">
            <DestinationsSection
              destinations={historicalCities}
              title={t('historicalEurope.title')}
              subtitle={t('historicalEurope.subtitle')}
              backgroundImage={'/assets/heros/hero8.webp'}
              button={t('historicalEurope.button')}
              preslug="/blog/destination"
            />

            <DestinationsSection
              destinations={italianDestinations}
              title={t('Italy.title')}
              subtitle={t('Italy.subtitle')}
              backgroundImage={'/assets/heros/manarola.jpg'}
              button={t('Italy.button')}
              preslug="/blog/destination"
            />
          </div>
        )}
      </main>

      <div className="pt-10 -mt-10">
        <div className="rounded-t-[60px] relative overflow-hidden bg-primary py-10 justify-center flex flex-col gap-16 items-center">
          <Meteors />

          <div className="container max-w-6xl flex flex-col justify-center gap-6 py-5 items-center h-full w-11/12 md:w-2/3">
            <span className="text-4xl md:text-5xl flex-col flex justify-start md:justify-center w-full text-white text-left md:text-center font-semibold opacity-80">
              <div>
                {t.rich('nightTrains.title', {
                  color: (chunks) => (
                    <p className="text-orange inline-block items-center"> {chunks}</p>
                  ),
                  br: () => <br />,
                })}
              </div>
            </span>
            <div className="w-[112%] sm:w-full relative">
              <Image
                alt="logo-google"
                src="/assets/screenshots/paris-berlin-exemple.png"
                width={1080}
                height={983}
                style={{
                  objectFit: 'contain',
                }}
                className="rounded-3xl z-10 w-full"
              />
              <Link
                href={`/search?nightTrain=true&origin=${encodeURIComponent(originName)}`}
              >
                <Button
                  className="absolute bottom-4 right-4 md:right-0 md:left-0 w-fit md:mx-auto"
                  variant="confirmative"
                  id="button-night-train-map"
                >
                  {t('nightTrains.testBtn')}
                </Button>
              </Link>
            </div>

            <div className="w-full h-1.5 rounded-full bg-orange" />
          </div>

          <TestimonialSection isDarkMode />

          <div className="container max-w-6xl flex flex-col justify-center bg-primary gap-5 items-center h-full w-11/12 md:w-2/3">
            <div className="flex flex-col justify-start h-full text-md md:text-xl font-semibold text-left w-full">
              <div className="text-white">
                {t.rich('nightTrains.features.title', {
                  color: (chunks) => <p className="text-orange items-center">{chunks}</p>,
                  br: () => <br />,
                })}
              </div>
            </div>

            <div className="flex flex-col gap-4 justify-start h-full text-2xl md:text-5xl text-white font-semibold w-full cursor-default">
              <p className="opacity-80 hover:text-orange hover:opacity-90">
                {t('nightTrains.features.feature1')}
              </p>
              <p className="opacity-70 hover:text-blue-500 hover:opacity-90">
                {t('nightTrains.features.feature2')}
              </p>
              <p className="opacity-60 hover:text-purpple hover:opacity-90">
                {t('nightTrains.features.feature3')}
              </p>
              <p className="opacity-50 hover:text-orange hover:opacity-90">
                {t('nightTrains.features.feature4')}
              </p>
              <p className="opacity-40 hover:text-blue-500 hover:opacity-90">
                {t('nightTrains.features.feature5')}
              </p>
              <p className="opacity-30 hover:text-purpple hover:opacity-90">
                {t('nightTrains.features.feature6')}
              </p>
              <p className="opacity-20 hover:text-orange hover:opacity-90">
                {t('nightTrains.features.feature7')}
              </p>
              <p className="opacity-15 hover:text-blue-500 hover:opacity-90">
                {t('nightTrains.features.feature8')}
              </p>
              <p className="opacity-10 hover:text-purpple hover:opacity-90">
                {t('nightTrains.features.feature9')}
              </p>
              <p className="opacity-5 hover:text-orange hover:opacity-90">
                {t('nightTrains.features.feature10')}
              </p>
            </div>
            <p className="text-white opacity-10 text-left w-full font-medium text-sm">
              {t('nightTrains.features.subtitle')}
            </p>
          </div>
          <div className="container max-w-6xl w-full justify-center flex">
            <button
              id="login"
              onClick={() => {
                setRedirectUrlToCurrentPage();
                setShowLogin(true);
              }}
              className="justify-center bg-orange text-white rounded-full md:w-1/2 hover:scale-110 h-28 md:h-40 font-semibold text-2xl md:text-3xl transition-transform focus:scale-95"
            >
              {t('LoginBtn.title')}
            </button>
            {showLogin && (
              <LoginModal
                onClose={() => {
                  setShowLogin(false);
                }}
              />
            )}
          </div>
        </div>
      </div>

      <AppFooter dark />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdWebsite) }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdOrganisation) }}
      />
    </>
  );
};

export default ClientPage;
